import Pogertvovanie from './pogertvovanie/pogertvovanie.jsx'

const Btn2 = ()=>{
    return(        
      
<div className="btn" >     
<div></div>                 
<Pogertvovanie/>
  <div></div>
  <a href="https://forms.gle/u4jwXUAXpjUwkxhGA" ><button className='btn3'>Анкета на служение</button></a>
  <div></div>
</div>
    )
}
export default Btn2