import '../App.css'

const Slugenie =()=>{
    return(
    <div>
       
<article className="slugenie_img" >
      <div className='slugenie_content'>
        <div className='slugenie_p'>
        <p>
  Если Вы готовы развивать семена бхакти в служении преданным и принимать активное участие в служении духовной семье Е.С. Бхакти Бхагаватамрита Кешава Свами, то просьба заполнить анкету команды служения. После чего с Вами обязательно свяжутся!
 </p>

          <p className='hand '>
          Бхаката-сева, парама-сиддхи, према-латикара мула – <br/><br/>
«Служение вайшнавам — высшее совершенство и корень нежного ростка божественной любви».
          </p>        
      </div>
      </div>      
 </article>
    </div>
    )
  }
  export default Slugenie