import '../App.css'

const Stupeni =()=>{
    return(
    <div>
<div className='fon'>
<center>               
<div className="title colorOrange">12 ступеней к <br/><span>установлению отношений</span><br/> с духовным учителем</div>
</center> 
<div className='img'>
<img className='imgFon' src="/mandala1.png" alt='img'/>
</div>
<div className='img2'>
<img className='imgFon' src="/mandala2.png" alt='img'/>
</div>


<h3 className='hand'>Знакомство</h3>
<p className='text_small'>
Знакомство кандидата и духовного учителя происходит при личной встрече (желательно) или через современные средства коммуникации, необходимо заполнить анкету на этом сайте
</p>


<h3 className='hand'>Испытательный срок</h3>
<p className='text_small'>
Духовный учитель и кандидат изучают друг друга в течение 1-6 месяцев. Кандидат делится своими проблемами и сомнениями, а духовный учитель дает советы. Кандидат слушает лекции и наставления учителя и старается применять их в своей жизни. Духовный учитель проверяет решимость и искренность ученика, его готовность строить настоящие, а не формальные отношения.
</p>


<h3 className='hand'>Принятие духовного учителя</h3>
<p className='text_small'>
По результатам общения кандидат принимает решение принять его учителя как полноправного духовного учителя и развиваться под его руководством. Он сообщает о своем желании учителю. Духовный учитель может согласиться или не согласиться на это предложение. В случае отказа он перенаправит к другому учителю.
</p>

<h3 className='hand'>Договоренности</h3>
<p className='text_small'>
Когда духовный учитель согласился заботиться о духовном развитии кандидата в ученики, они вместе определяют формат и регулярность общения.
</p>

<h3 className='hand'>Зачисление</h3>
<p className='text_small'>
Духовный учитель сообщает секретарю о новом кандидате в ученики, а секретарь связывается с кандидатом по предоставленным контактам.
</p>

<h3 className='hand'>Отчетность</h3>
<p className='text_small'>
Кандидат в ученики регулярно (желательно раз в месяц) отчитывается о своей ежедневной практике, об успехах в духовном образовании, о служении. Получая конкретные наставления, кандидат в ученики рассказывает об их выполнении.
</p>

<h3 className='hand'>Обучение</h3>
<p className='text_small'>
Духовный учитель наблюдает за тем, как кандидат в ученики следует садхане и обучается. Он также поддерживает связь с местным руководством ятры или с наставниками.
</p>

<h3 className='hand'>Аттестация</h3>
<p className='text_small'>
Духовный учитель оценивает готовность кандидата в ученики к получению пранама-мантры не только по результатам регулярной практики и образования, но и по тому, насколько доверительные отношения сформировались в процессе общения с ним.
</p>

<h3 className='hand'>Собеседование</h3>
<p className='text_small'>
Когда кандидат доказал свою квалификацию и готов начать повторять пранама-мантру, духовный учитель проводит личную беседу.
</p>

<h3 className='hand'>Пранама-мантра</h3>
<p className='text_small'>
Если духовный учитель согласен принять кандидата в ученики, то он дает разрешение повторять пранама-мантру. С этого момента кандидат может начать готовиться к посвящению.
</p>

<h3 className='hand'>Первое посвящение</h3>
<h3 className='hand'>Второе посвящение</h3>
</div>



</div>
)
}
export default Stupeni