import Modal from './modal/Modal'


const Btn = ()=>{
    return(
      <div className="btn" >     
      <div></div>                 
        <Modal/>
        <div></div>
        <a href="https://forms.gle/u4jwXUAXpjUwkxhGA" ><button className='btn2'>Анкета кандидата</button></a>
        <div></div>
      </div> 
    )
}
export default Btn