import '../App.css'

const Vagnost =()=>{
    return(
    <div>

 <article className="gita_img" >
      <div className='gita_content'>
        <div className='gita_p'>
          <p className='hand '>
          "тад виддхи пранипатена парипрашнена севайа<br/>
          упадекшйанти те джнанам джнанинас таттва-даршинах"
          </p>
          <h2>
          "Чтобы узнать истину, вручи себя духовному учителю. <br/>
          Вопрошай его смиренно и служи ему. <br/>
          Осознавшие себя души могут дать тебе знание, ибо они узрели истину".
          </h2>
          <p>Бхагавад-гита 4.34</p>
        </div>
      </div>      
 </article>

</div>
)
}
export default Vagnost